export const TIMES = [
  {
    value: 'today',
    label: 'global.today',
  },
  {
    value: 'this_week',
    label: 'global.week',
  },
  {
    value: 'this_month',
    label: 'global.month',
  },
  {
    value: 'this_year',
    label: 'global.year',
  },
];

export const PAGINATION_PARAMS = {
  page: 1,
  limit: 10,
};

export const PRODUCT_PAGINATION_PARAMS = {
  page: 1,
  limit: 12,
};

export const ORDER_STATUSES = {
  CONFIRMED: 'confirmed',
  UNCONFIRMED: 'unconfirmed',
};

export const CUSTOMER_TYPES = [
  {
    value: 'standard',
    label: 'global.standardCustomer',
  },
  {
    value: 'beloved',
    label: 'global.belovedCustomer',
  },
  {
    value: 'special',
    label: 'global.specialCustomer',
  },
];

export const LOCALE = {
  EN: 'en',
  KO: 'ko',
};

export const VERSION = {
  name: '_v',
  number: 1,
};
