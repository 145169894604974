import axios from 'axios';
import queryString from 'query-string';
// import { isArray } from 'lodash';

import Notification from '../components/Notification';
import { handleLogout } from '../features/auth/actions';
import { handleErrorMessage } from '../utils/handleError';

const axiosClient = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}`,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  timeout: 30000,
  paramsSerializer: (params) => {
    return queryString.stringify(params, {
      skipNull: true,
      skipEmptyString: true,
    });
  },
});

const httpService = {
  setupInterceptors: (store) => {
    axiosClient.interceptors.request.use(
      (config) => {
        const token = localStorage.getItem('token');

        if (token) {
          // eslint-disable-next-line no-param-reassign
          config.headers.Authorization = `${token}`;
        }

        return config;
      },
      (error) => {
        return Promise.reject(error);
      },
    );
    axiosClient.interceptors.response.use(
      (response) => {
        if (response && response.data) {
          // if (isArray(response.data) && response.headers['x-total-count']) {
          //   const total = parseInt(response.headers['x-total-count'], 10) || 0;

          //   return {
          //     data: response.data,
          //     total,
          //   };
          // }

          return response.data.data;
        }

        return response;
      },
      (error) => {
        const errorMessage = handleErrorMessage(error);

        if (
          error.response &&
          (error.response.status === 401 || error.response.status === 403)
        ) {
          store.dispatch(handleLogout());
        }

        console.log('errorMessage: ', errorMessage);
        Notification('error', errorMessage.message);

        return Promise.reject(errorMessage);
      },
    );
  },
};

export default axiosClient;
export { httpService };
