export const GET_PRODUCT_LIST = 'features/product/GET_PRODUCT_LIST';

export const GET_PRODUCT_OVERVIEW = 'features/product/GET_PRODUCT_OVERVIEW';

export const GET_PRODUCT_UNIT = 'features/product/GET_PRODUCT_UNIT';

export const GET_PRODUCT_METRIC = 'features/product/GET_PRODUCT_METRIC';

export const GET_LIST_CATEGORY = 'features/product/GET_LIST_CATEGORY';
export const CREATE_CATEGORY = 'features/product/CREATE_CATEGORY';
export const DEL_ITEM_CATEGORY = 'features/product/DEL_ITEM_CATEGORY';
export const DEL_ITEM_CATEGORY_SUCCESS =
  'features/product/DEL_ITEM_CATEGORY_SUCCESS';
export const ADD_PRODUCT = 'features/product/ADD_PRODUCT';
export const EDIT_PRODUCT = 'features/product/EDIT_PRODUCT';
export const DELETE_PRODUCT = 'feature/product/DELETE_PRODUCT';
