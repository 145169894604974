import { all } from 'redux-saga/effects';

import authSagas from '../features/auth/sagas';
import settingSagas from '../features/setting/sagas';
import trendSagas from '../features/trend/sagas';
import profileSagas from '../features/setting/pages/profile/sagas';
import customerSagas from '../features/customer/sagas';
import orderSagas from '../features/order/sagas';
import productSagas from '../features/product/sagas';

export default function* rootSaga() {
  yield all([
    ...authSagas,
    ...settingSagas,
    ...orderSagas,
    ...trendSagas,
    ...profileSagas,
    ...productSagas,
    ...customerSagas,
  ]);
}
