import axiosClient from './axiosClient';

const uploadImage = ({ file }) => {
  const formData = new FormData();
  formData.append('file', file);

  return axiosClient.post('/uploads/image', formData);
};

export default uploadImage;
