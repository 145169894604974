import SELLER from '../assets/images/seller.png';
import KAKAO_LOGO from '../assets/images/kakao-talk.png';
import ITEM_LOGO from '../assets/images/item-logo.png';
import PRODUCT_IMAGE from '../assets/images/product-img.png';
import BADGE_GOLD from '../assets/images/level-badge-gold.png';
import BADGE_VIOLET from '../assets/images/level-badge-violet.png';
import BADGE_BLACK from '../assets/images/level-badge-black.png';
import TREND_BG from '../assets/images/trend-bg.png';
import ICO_EN from '../assets/images/ico-en.png';
import ICO_KO from '../assets/images/ico-kr.png';
import EMPTY_IMAGE from '../assets/images/empty-image.png';
import ORDER_EASY_LOGO from '../assets/images/logo.png';
import ORDER_EASY_SMALL_LOGO from '../assets/images/log_sm.png';
import LOGO_LOGIN from '../assets/images/logo_login.png';

const IMAGES = {
  SELLER,
  KAKAO_LOGO,
  ITEM_LOGO,
  PRODUCT_IMAGE,
  BADGE_GOLD,
  BADGE_VIOLET,
  BADGE_BLACK,
  TREND_BG,
  ICO_EN,
  ICO_KO,
  EMPTY_IMAGE,
  ORDER_EASY_LOGO,
  ORDER_EASY_SMALL_LOGO,
  LOGO_LOGIN,
};

export default IMAGES;
