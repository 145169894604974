import axiosClient from './axiosClient';

const productApi = {
  getProductList: (params) => {
    return axiosClient.get('/products', { params });
  },
  getProductOverview: (productId, params) => {
    return axiosClient.get(`/products/${productId}/overview`, { params });
  },
  getProductUnit: (params) => {
    return axiosClient.get('/units', { params });
  },
  getProductMetric: (params) => {
    return axiosClient.get('/metrics', { params });
  },
  getCategoryList: (params) => {
    return axiosClient.get('/categories', { params });
  },
  delItemCategory: (categoryId) => {
    return axiosClient.delete(`/categories/${categoryId}`);
  },
  createCategory: (data) => {
    return axiosClient.post('/categories', data);
  },
  addProduct: (data) => {
    return axiosClient.post('/products', data);
  },
  editProduct: (productId, data) => {
    return axiosClient.put(`/products/${productId}`, data);
  },
  deleteProduct: (id) => {
    return axiosClient.delete(`/products/${id}`);
  },
};

export default productApi;
