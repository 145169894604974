import { takeEvery, call, put } from 'redux-saga/effects';
import * as actionTypes from './actionTypes';
import { doSetLoading } from '../../app/actions';

import clientApi from '../../api/clientApi';

function* handleGetListCustomer({ payload }) {
  const { params, callback } = payload;

  yield put(doSetLoading(true));
  try {
    const result = yield call(clientApi.getList, params);

    callback({ error: false, data: result });
  } catch (error) {
    callback({ error: true });
  }
  yield put(doSetLoading(false));
}

function* handleUpdateBuyerType({ payload }) {
  const { data, callback } = payload;

  yield put(doSetLoading(true));
  try {
    const result = yield call(clientApi.updateBuyerType, data);

    callback({ error: false, data: result });
  } catch (error) {
    callback({ error: true });
  }
  yield put(doSetLoading(false));
}

export default [
  takeEvery(actionTypes.GET_LIST_CUSTOMER, handleGetListCustomer),
  takeEvery(actionTypes.UPDATE_BUYER_TYPE, handleUpdateBuyerType),
];
