import axiosClient from './axiosClient';

const authApi = {
  login: (data) => {
    return axiosClient.post('/auth/loginByEmail', data);
  },
  verifyToken: (accessToken) => {
    return axiosClient.post('/auth/verifyToken', { accessToken });
  },
  resetPassword: (data) => {
    return axiosClient.post('/auth/resetPassword', data);
  },
  restorePassword: (data) => {
    return axiosClient.post('/auth/restorePassword', data);
  },
};

export default authApi;
