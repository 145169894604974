import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import autoMergeLevel1 from 'redux-persist/lib/stateReconciler/autoMergeLevel1';

import auth from '../features/auth/reducers';
import app from '../app/reducers';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['app', 'auth'],
  // blacklist: ['router'],
  version: 1,
  stateReconciler: autoMergeLevel1,
};

export default function createRootReducer(history) {
  const rootReducer = combineReducers({
    auth,
    app,
    router: connectRouter(history),
  });

  // return rootReducer;
  return persistReducer(persistConfig, rootReducer);
}
