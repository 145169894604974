import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { PersistGate } from 'redux-persist/integration/react';
import * as serviceWorker from './serviceWorker';

import App from './app/views/App';
import configureStore from './store';
import history from './utils/history';
import { httpService } from './api/axiosClient';
import { VERSION } from './constants/global';

const version = localStorage.getItem(VERSION.name);
if (version !== `${VERSION.number}`) {
  localStorage.clear();
}

// Create redux store with history
const initialState = {};
const { store, persistor } = configureStore(initialState, history);

httpService.setupInterceptors(store);

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <ConnectedRouter history={history}>
        <App />
      </ConnectedRouter>
    </PersistGate>
  </Provider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
