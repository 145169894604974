import { takeEvery, call, put } from 'redux-saga/effects';
import * as actionTypes from './actionTypes';
import { doSetLoading } from '../../app/actions';

import productApi from '../../api/productApi';
import uploadImage from '../../api/uploadImage';

function* handleGetProductList({ payload }) {
  const { params, callback } = payload;

  yield put(doSetLoading(true));
  try {
    const result = yield call(productApi.getProductList, params);
    callback({ error: false, data: result });
  } catch (error) {
    callback({ error: true });
  }
  yield put(doSetLoading(false));
}

function* handleGetProductOverview({ payload }) {
  const { params, callback, productId } = payload;
  try {
    const result = yield call(productApi.getProductOverview, productId, params);
    callback({ error: false, data: result });
  } catch (error) {
    callback({ error: true });
  }
}

function* handleGetProductUnit({ payload }) {
  const { params, callback } = payload;
  try {
    const result = yield call(productApi.getProductUnit, params);
    callback({ error: false, data: result });
  } catch (error) {
    callback({ error: true });
  }
}

function* handleGetProductMetric({ payload }) {
  const { params, callback } = payload;
  try {
    const result = yield call(productApi.getProductMetric, params);
    callback({ error: false, data: result });
  } catch (error) {
    callback({ error: true });
  }
}

function* handleGetCategoryList({ payload }) {
  const { params, callback } = payload;

  try {
    const result = yield call(productApi.getCategoryList, params);
    callback({ error: false, data: result });
  } catch (error) {
    callback({ error: true });
  }
}

function* handleDelCategory({ payload }) {
  const { callback, categoryId } = payload;
  try {
    const result = yield call(productApi.delItemCategory, categoryId);
    callback({ error: false, data: result });
  } catch (error) {
    callback({ error: true });
  }
}

function* handleCreateCategory({ payload }) {
  const { data, callback } = payload;
  try {
    const result = yield call(productApi.createCategory, data);
    callback({ error: false, data: result });
  } catch (error) {
    callback({ error: true });
  }
}

function* handleAddProduct({ payload }) {
  const { data, callback } = payload;
  try {
    const { image, ...restData } = data;
    let newData = { ...restData };

    if (image) {
      const imageResult = yield call(uploadImage, { file: image });
      newData = { ...newData, images: imageResult.url };
    }

    const result = yield call(productApi.addProduct, newData);
    callback({ error: false, data: result });
  } catch (error) {
    callback({ error: true });
  }
}

function* handleEditProduct({ payload }) {
  const { data, callback, productId } = payload;
  console.log('function*handleEditProduct -> data', data);
  console.log('function*handleEditProduct -> productId', productId);
  try {
    const { image, ...restData } = data;
    let newData = { ...restData };

    if (image) {
      const imageResult = yield call(uploadImage, { file: image });
      newData = { ...newData, images: imageResult.url };
    }

    const result = yield call(productApi.editProduct, productId, newData);
    callback({ error: false, data: result });
    console.log('function*handleEditProduct -> result', result);
  } catch (error) {
    callback({ error: true });
  }
}

function* handleDeleteProduct({ payload }) {
  const { id, callback } = payload;
  try {
    const result = yield call(productApi.deleteProduct, id);
    callback({ error: false, data: result });
  } catch (error) {
    callback({ error: true });
  }
}

export default [
  takeEvery(actionTypes.GET_PRODUCT_LIST, handleGetProductList),
  takeEvery(actionTypes.GET_PRODUCT_OVERVIEW, handleGetProductOverview),
  takeEvery(actionTypes.GET_PRODUCT_UNIT, handleGetProductUnit),
  takeEvery(actionTypes.GET_PRODUCT_METRIC, handleGetProductMetric),
  takeEvery(actionTypes.GET_LIST_CATEGORY, handleGetCategoryList),
  takeEvery(actionTypes.CREATE_CATEGORY, handleCreateCategory),
  takeEvery(actionTypes.DEL_ITEM_CATEGORY, handleDelCategory),
  takeEvery(actionTypes.ADD_PRODUCT, handleAddProduct),
  takeEvery(actionTypes.EDIT_PRODUCT, handleEditProduct),
  takeEvery(actionTypes.DELETE_PRODUCT, handleDeleteProduct),
];
