import { takeEvery, call, put } from 'redux-saga/effects';
import * as actionTypes from './actionTypes';
import { doSetLoading } from '../../app/actions';

import orderApi from '../../api/orderApi';

function* handleGetOrderList({ payload }) {
  const { params, callback } = payload;

  yield put(doSetLoading(true));
  try {
    const result = yield call(orderApi.get, params);

    callback({ error: false, data: result });
  } catch (error) {
    callback({ error: true });
  }
  yield put(doSetLoading(false));
}

function* handleConfirmOrder({ payload }) {
  const { data, callback } = payload;
  try {
    const result = yield call(orderApi.confirm, data);

    callback({ error: false, data: result });
  } catch (error) {
    callback({ error: true });
  }
}

export default [
  takeEvery(actionTypes.GET_ORDER_LIST, handleGetOrderList),
  takeEvery(actionTypes.CONFIRM_ORDER, handleConfirmOrder),
];
