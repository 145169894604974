import { takeEvery, call } from 'redux-saga/effects';
import * as actionTypes from './actionTypes';

import userApi from '../../../../api/userApi';
import uploadImage from '../../../../api/uploadImage';

function* handleGetUserProfile({ payload }) {
  const { params, callback } = payload;
  try {
    const result = yield call(userApi.get, params);

    callback({ error: false, data: result.info });
  } catch (error) {
    callback({ error: true });
  }
}

function* handleUpdateUserProfile({ payload }) {
  const { data, callback } = payload;
  try {
    const { image, ...restData } = data;
    let newData = { ...restData };

    if (image) {
      const imageResult = yield call(uploadImage, { file: image });

      newData = { ...newData, companyLogo: imageResult.url };
    }

    const result = yield call(userApi.update, newData);
    callback({ error: false });
  } catch (error) {
    callback({ error: true });
  }
}

export default [
  takeEvery(actionTypes.GET_USER_PROFILE, handleGetUserProfile),
  takeEvery(actionTypes.UPDATE_USER_PROFILE, handleUpdateUserProfile),
];
