import { takeEvery, call, put } from 'redux-saga/effects';
import * as actionTypes from './actionTypes';
import { doSetLoading } from '../../app/actions';

import orderApi from '../../api/orderApi';

function* handleGetStatistics({ payload }) {
  const { params, callback } = payload;

  yield put(doSetLoading(true));
  try {
    const result = yield call(orderApi.getStatistics, params);
    callback({ error: false, data: result });
  } catch (error) {
    callback({ error: true });
  }
  yield put(doSetLoading(false));
}

export default [takeEvery(actionTypes.GET_STATISTICS, handleGetStatistics)];
