import axiosClient from './axiosClient';

const userApi = {
  get: (params) => {
    return axiosClient.get('/users', { params });
  },
  update: (data) => {
    return axiosClient.put('/users', data);
  },
};

export default userApi;
