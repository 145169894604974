import axiosClient from './axiosClient';

const orderApi = {
  getStatistics: (params) => {
    return axiosClient.get('/orders/statistics', { params });
  },
  get: (params) => {
    return axiosClient.get('/orders', { params });
  },
  confirm: (data) => {
    return axiosClient.post('/orders/confirm', data);
  },
};

export default orderApi;
