import { takeEvery, put, call } from 'redux-saga/effects';
import * as actionTypes from './actionTypes';
import * as actions from './actions';

import authApi from '../../api/authApi';
import { VERSION } from '../../constants/global';

function* handleLogin({ payload }) {
  const { data, callback } = payload;
  try {
    const result = yield call(authApi.login, data);
    const { accessToken: token, info } = result;

    yield localStorage.setItem('token', token);
    yield localStorage.setItem(VERSION.name, VERSION.number);

    callback({ error: false });

    yield put(actions.handleLoginSuccess(info));
  } catch (error) {
    callback({ error: true });
    yield put(actions.handleLoginError());
  }
}

function* handleResetPassword({ payload }) {
  const { data, callback } = payload;
  try {
    const result = yield call(authApi.resetPassword, data);

    callback({ error: false, data: result });
  } catch (error) {
    callback({ error: true, errors: error.errors });
  }
}

function* handleLogout() {
  yield localStorage.clear();
}

function* handleCheckAuth() {
  const token = localStorage.getItem('token');

  // Verify token here with API
  try {
    const result = yield call(authApi.verifyToken, token);

    yield put(actions.handleLoginSuccess(result.info));
  } catch (error) {
    yield put(actions.handleLogout());
  }
}

function* handleRestorePassword({ payload }) {
  const { data, callback } = payload;
  try {
    const result = yield call(authApi.restorePassword, data);

    callback({ error: false, data: result });
  } catch (error) {
    callback({ error: true });
  }
}

export default [
  takeEvery(actionTypes.LOGIN, handleLogin),
  takeEvery(actionTypes.LOGOUT, handleLogout),
  takeEvery(actionTypes.CHECK_AUTH, handleCheckAuth),
  takeEvery(actionTypes.RESET_PASSWORD, handleResetPassword),
  takeEvery(actionTypes.RESTORE_PASSWORD, handleRestorePassword),
];
