import axiosClient from './axiosClient';

const clientApi = {
  getList: (params) => {
    return axiosClient.get('/clients', { params });
  },
  updateBuyerType: ({ id, ...data }) => {
    return axiosClient.patch(`/clients/${id}/updateBuyerType`, data);
  },
};

export default clientApi;
